import { memo as Memo, useMemo, useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import { useRouter } from 'next/router'

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Components
import Animate from 'components/common/Animate'
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import Image from 'components/common/Image'
import Video from 'components/common/Video'

//* Style
import HeroStyle from './style'

const Hero = Memo(({ file, text, winWidth }) => {
    //! Router
    const router = useRouter()

    //! Refs
    const globalRef = useRef()
    const imageScrollRef = useRef()

    const leftRef = useRef()
    const rightRef = useRef()

    const heroStore = useMemo(() => {
        return (
            <Container className={`heroInfoWrap`}>
                <Animate
                    stagger={0.1}
                    delay={0.45}
                    durationOpacity={0.3}
                    startY={'80%'}
                    params={{ opacityVertical: [leftRef, rightRef] }}
                    dependency={router.asPath}
                >
                    <div className={`leftWrap opacityZero`} ref={leftRef}>
                        <Text tag={`p`} className={`h4 font-montserratarm-regular primaryColor1 uppercase`} text={`homeHeroText_1`} />

                        <Text tag={`p`} className={`h5 font-montserratarm-light primaryColor1 uppercase addStyle`} text={`homeHeroText_2`} />
                    </div>

                    <div className={`rightWrap opacityZero`} ref={rightRef}>
                        <div className={`interWrap`}>
                            <Text tag={`h1`} className={`p3 font-montserratarm-regular primaryColor1`} text={text} />
                        </div>
                    </div>
                </Animate>
            </Container>
        )
    }, [text])

    //! GlobalStore
    const globalStore = useMemo(() => {
        return (
            <div className={`globalWrapper`} ref={globalRef}>
                {heroStore}

                {file && (
                    <Animate params={{ squareClipPathAnim: [imageScrollRef] }} dependency={router.asPath}>
                        {file.type === 'image' ? (
                            <Image
                                className={`squareClipPathDefaultState`}
                                ref={imageScrollRef}
                                objectFit={'cover'}
                                src={file?.url}
                                alt={file?.alt}
                            />
                        ) : (
                            <Video className={`squareClipPathDefaultState`} ref={imageScrollRef} src={file?.url} autoPlay={true} loop={true} />
                        )}
                    </Animate>
                )}
            </div>
        )
    }, [heroStore, winWidth, file, router.asPath])

    return (
        <HeroStyle >
            {globalStore}
        </HeroStyle>
    )
})

export default withUIContext(Hero, ['winWidth', 'screenSizes'])
