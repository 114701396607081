import styled from 'styled-components'

const HeroStyle = styled.section.attrs((props) => ({}))`
	--fileWidth: 1576;
	--leftWrapWidth: 57.7%;
	--rightWrapWidth: 19.9%;
	--fileHeight: 56.6%;

	--globalWrapMarginSize: calc(-1 * var(--sp22x));

	margin-bottom: var(--sectionsDistance);

	.squareClipPathDefaultState {
		clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
	}

	.opacityZero {
		opacity: 0;
	}

	.globalWrapper {
		position: relative;

		.imageCont,
		.videoCont {
			transform: scale(1) translate3d(0, 0, 0);
			z-index: -1;
			padding-top: var(--fileHeight);
		}

		.heroInfoWrap {
			position: absolute;
			bottom: 0;
			left: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
            margin-bottom: 3%;

			.leftWrap {
				width: var(--leftWrapWidth);
			}

			.rightWrap {
				width: var(--rightWrapWidth);
			}
		}

		margin-top: var(--globalWrapMarginSize);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeL}) {
		--fileWidth: 1184;
		--leftWrapWidth: 57.6%;
		--rightWrapWidth: 22.3%;
		--fileHeight: 56.6%;

		--globalWrapMarginSize: calc(-1 * var(--sp15x));
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeM}) {
		--fileWidth: 928;
		--leftWrapWidth: 65.8%;
		--rightWrapWidth: 23.5%;
		--fileHeight: 56.6%;

		--globalWrapMarginSize: calc(-1 * var(--sp11x));
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeS}) {
		--fileWidth: 928;
		--leftWrapWidth: 57.5%;
		--rightWrapWidth: 23.6%;
		--fileHeight: 56.6%;

		--globalWrapMarginSize: calc(-1 * var(--sp11x));
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.DesktopSizeXS}) {
		--fileWidth: 824;
		--leftWrapWidth: 57.4%;
		--rightWrapWidth: 23.5%;
		--fileHeight: 56.6%;

		--globalWrapMarginSize: calc(-1 * var(--sp9x));

		.addStyle {
			margin-top: var(--sp1x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSize}) {
		--fileWidth: 656;
		--leftWrapWidth: 57.3%;
		--rightWrapWidth: 27%;
		--fileHeight: 56.6%;

		--globalWrapMarginSize: calc(-1 * var(--sp2x));

		.addStyle {
			margin-top: var(--sp1x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) =>
        props.theme.mediaQuery.TabletSizeS}) {
		--fileWidth: 488;
		--leftWrapWidth: 65.6%;
		--rightWrapWidth: 31.2%;
		--fileHeight: 56.6%;

		--globalWrapMarginSize: var(--sp2x);

		.addStyle {
			margin-top: var(--sp1x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--fileWidth: 392;
		--leftWrapWidth: 100%;
		--rightWrapWidth: 100%;
		--fileHeight: 56.6%;

		.globalWrapper {
			margin-top: 0;

			.imageCont,
			.videoCont {
				position: absolute;
				top: 0;
				left: 0;
				transform: scale(1) translate3d(0, 0, 0) !important;
				margin-left: var(--sp1x);
				width: calc(100% - var(--sp2x));
			}

			.heroInfoWrap {
				position: relative;

				.leftWrap {
					margin-top: 50%;
				}

				.rightWrap {
					margin-top: var(--sp3x);

					.interWrap {
						width: 65.7%;
					}
				}
			}
		}

		.addStyle {
			margin-top: var(--sp1x);
		}
	}
`

export default HeroStyle
